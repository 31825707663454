(function ($) {
  Drupal.behaviors.toutImageV1 = {
    attach: function (context, settings) {
      $('.tout-image-v1').each(function () {
        var thisFormatter = $(this);
        var link = thisFormatter.find('a');
        var path = link.attr('href');

        if (typeof path !== 'undefined') {
          thisFormatter.css('cursor', 'pointer').on('click', function() {
            link.trigger('click');
          });
        }
      });
    }
  };
})(jQuery);
